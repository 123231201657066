import type { App } from "vue";
import Y9Container from "./Y9Container.vue";

const globalComponents = {
  Y9Container,
};

export type Components = typeof globalComponents;

export const registerGlobalComponents = (app: App) => {
  for (const key in globalComponents) {
    app.component(key, globalComponents[key as keyof Components]);
  }
};
