<script setup lang="ts">
import { onLaunch, onShow, onHide } from "@dcloudio/uni-app";

onLaunch(() => {});
onShow(() => {
  console.log("App Show");
});
onHide(() => {
  console.log("App Hide");
});
</script>
<style>
@import url("./assets/twindcss.base.css");

html,
page {
  --background-color-page: #ededed;
  --background-color-page-inverse: white;
  --text-color: #333;
  --text-color-secondary: #666;
  --text-color-placeholder: #999;
  --text-color-inverse: white;
  --text-color-placeholder-inverse: #ddd;
  --border-color: rgba(0, 0, 0, 0.08);
  color: var(--text-color);

  @apply select-none;
}
@media (prefers-color-scheme: dark) {
  /* DarkMode 下的样式 start */
  html,
  page {
    --background-color-page: #111;
    --background-color-page-inverse: #222;
    --text-color: white;
    --text-color-secondary: #ddd;
    --text-color-placeholder: #aaa;
    --text-color-inverse: #333;
    --border-color: rgba(255, 255, 255, 0.1);
  }
}
page {
  @apply h-full bg-page;
}
</style>
